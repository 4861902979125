import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import Routing from '../pages';
import { AppWrapper } from './app.styles';
import './i18n/config';
import { ThemeContainer } from './themes/theme-container';
import { ThemeInterceptor } from './themes/theme-interceptor';
import { WebAnalytic } from './web-analytic';
import { AppContent } from '@features/app-content';
import { AgentController } from '@features/index';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AgentController />
        <WebAnalytic />
        <ThemeContainer>
          <AppContent>
            <AppWrapper>
              <ThemeInterceptor />
              <Routing />
            </AppWrapper>
          </AppContent>
        </ThemeContainer>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
