/* eslint-disable @typescript-eslint/no-explicit-any */
import amplitude from 'amplitude-js';
import { FC, memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ym, { YMInitializer } from 'react-yandex-metrika';

import { analyticEvents } from '@app/web-analytic';
import { useStores } from '@shared/hooks';

const YM_COUNTER_KEY = Number(window.envKeys.YM_COUNTER_KEY);
const AMPLITUDE_API_KEY = String(window.envKeys.AMPLITUDE_API_KEY);
const ymInitEventType = `yacounter${YM_COUNTER_KEY}inited`;
const YM_AGENT_COUNTER_KEY = Number(window.envKeys.YM_AGENT_COUNTER_KEY);
const AMPLITUDE_AGENT_API_KEY = String(window.envKeys.AMPLITUDE_AGENT_API_KEY);
const ymAgentInitEventType = `yacounter${YM_AGENT_COUNTER_KEY}inited`;

export const WebAnalytic: FC = memo(() => {
  const {
    MainStore: {
      applicationStore: { sendSingleAnalyticEvent },
      productStore: { agentLogin: agentLoginFormStore },
    },
  } = useStores();

  const [searchParams] = useSearchParams();
  const utmPayload: Record<string, any> = {};
  const clientCategory = localStorage.getItem('clientCategory');
  const agentLogin = searchParams.get('agent_login');

  const isAgentFlow = !!agentLogin || !!agentLoginFormStore;

  // const { pathname } = useLocation();

  // useEffect(() => {
  //   if (
  //     pathname !== PAYMENT_ROUTE &&
  //     pathname !== PAYMENT_ERROR_ROUTE &&
  //     pathname !== PAYMENT_SUCCESS_ROUTE &&
  //     pathname !== PAYMENT_IN_PROGRESS_ROUTE
  //   ) {
  //     clearLocalStorage();
  //   }
  // }, []);

  useEffect(() => {
    amplitude
      .getInstance()
      .init(
        isAgentFlow ? AMPLITUDE_AGENT_API_KEY : AMPLITUDE_API_KEY,
        undefined,
        {
          platform: searchParams.get('platform') || 'Web',
          includeUtm: true,
        }
      );

    amplitude.getInstance().setUserProperties({
      media_source: searchParams.get('media_source') || '0000',
      campaign: searchParams.get('campaign') || 'WEB',
      utm_source: searchParams.get('utm_source') || 0,
      utm_medium: searchParams.get('utm_medium') || 0,
      utm_content: searchParams.get('utm_content') || 0,
      wm_id: searchParams.get('wm_id') || 0,
      utm_campaign: searchParams.get('utm_campaign') || 0,
    });
  }, [AMPLITUDE_API_KEY, AMPLITUDE_AGENT_API_KEY, isAgentFlow]);

  useEffect(() => {
    const addUserParams = () => {
      ym('userParams', {
        clientCategory: clientCategory,
      });
    };

    const ymListenerType = isAgentFlow ? ymAgentInitEventType : ymInitEventType;

    amplitude.getInstance().setUserProperties({
      clientCategory: clientCategory,
    });

    document.addEventListener(ymListenerType, addUserParams);

    return () => document.removeEventListener(ymListenerType, addUserParams);
  }, [clientCategory, isAgentFlow]);

  //TODO: в стори 4127 перенести из IsSubscriptionExistsPage в AppContent логику для первого запуска pulseAppInit
  useEffect(() => {
    if (searchParams.get('utm_source')) {
      utmPayload.utm_source = searchParams.get('utm_source');
    }

    if (searchParams.get('utm_campaign')) {
      utmPayload.utm_campaign = searchParams.get('utm_campaign');
    }

    if (searchParams.get('utm_content')) {
      utmPayload.utm_content = searchParams.get('utm_content');
    }

    if (searchParams.get('utm_medium')) {
      utmPayload.utm_medium = searchParams.get('utm_medium');
    }

    if (searchParams.get('utm_media_source')) {
      utmPayload.utm_media_source = searchParams.get('utm_media_source');
    }
    if (searchParams.get('campaign')) {
      utmPayload.campaign = searchParams.get('campaign');
    }
    if (searchParams.get('media_source')) {
      utmPayload.media_source = searchParams.get('media_source');
    }
    if (searchParams.get('wm_id')) {
      utmPayload.wm_id = searchParams.get('wm_id');
    }

    // dispatch({
    //   type: UserActionTypes.SetRegUtm,
    //   payload: utmPayload,
    // });
  }, []);

  //TODO: После оплаты приложение перезагружается, и мы повторно инициализируем метрики выше и отправляем этот ивент. Исправить.
  useEffect(() => {
    sendSingleAnalyticEvent(analyticEvents.webStart);
  }, []);

  return (
    <YMInitializer
      accounts={[isAgentFlow ? YM_AGENT_COUNTER_KEY : YM_COUNTER_KEY]}
      options={{
        triggerEvent: true,
        clickmap: true,
        childIframe: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        trustedDomains: ['https://pulse.rusrobots.ru/', 'https://pulse.insure'],
        params: {
          media_source: searchParams.get('media_source') || '0000',
          campaign: searchParams.get('campaign') || 'WEB',
          utm_source: searchParams.get('utm_source') || 0,
          utm_medium: searchParams.get('utm_medium') || 0,
          utm_content: searchParams.get('utm_content') || 0,
          wm_id: searchParams.get('wm_id') || 0,
          utm_campaign: searchParams.get('utm_campaign') || 0,
          clientCategory: 'STANDART',
        },
      }}
      version="2"
    />
  );
});
